import { useEffect, useRef } from 'react';
import { useState } from 'react'
import FixedFooter from '../components/MentionsFooter';
import { prestations } from '../data';
import monte from '../images/prestations/monte.jpg';
import longuesrenes from '../images/prestations/longuesrenes.jpg';
import apied from '../images/prestations/apied.jpg';
import coaching from '../images/prestations/coaching.jpg';
import aupre from '../images/prestations/NEtB/aupre.jpg';
import aubox from '../images/prestations/NEtB/aubox.jpg';
import carriere from '../images/prestations/NEtB/carriere.jpg';
import airePansage from '../images/prestations/NEtB/airePansage.jpg';
import MentionsFooter from '../components/MentionsFooter';

interface PrestaPhotoI{
  id: number,
  path: string,
  alt: string
}

interface PrestaI{
  id: number , 
  category : string, 
  details : detailsI[]
}

interface detailsI{
  id: number,
  title: string,
  label: string,
  price: string
}
export default function Prestations({...props}) {
 
  const [isClicked, setIsClicked] = useState(-1);
  const [prestaClass, setPrestaClass] = useState('presta-details-hidden');
  const [prestaCoord, setPrestaCoord] = useState({top: -1, left:-1});

  const timerRef = useRef<any>(null);
  const workPhotos = [
    {id: 0, path: monte, alt: "Travail monté"},
    {id: 1, path: apied, alt: "Travail à pied"},
    {id: 2, path: longuesrenes, alt: "Longues rênes"},
    {id: 3, path: coaching, alt: "Coaching"}
  ]
  const installPhotos = [
    {id: 0, path: aupre, alt: "Au pré"},
    {id: 1, path: aubox, alt: "Au box"},
    {id: 2, path: carriere, alt: "En carrière"},
    {id: 3, path: airePansage, alt: "Au pansage"}
  ]

// didmount
useEffect(() => {
  window.scrollTo({top: 0,left: 0, behavior:'smooth'});
  props.setActivNavElt(props.id);
  return()=>{
    props.setActivNavElt(-1);
  }
}, []) 
//didupdate
 useEffect(() => {
  if (prestaCoord !== undefined && prestaCoord.top !== -1 && prestaCoord.left !== -1)
  window.scrollTo({top: prestaCoord.top, left: prestaCoord.left, behavior:'smooth'});
 
    console.log("isClicked : " + isClicked);
   return () => {
    
   }
 })
 
  const handlePrestaClick =(id: number)=> {
   
    setIsClicked(id === isClicked ? -1 : id);
    setPrestaClass(isClicked === id ? (prestaClass === 'presta-details-hidden' ? 'presta-details' : 'presta-details-hidden'):'presta-details-hidden');
    // scroll to top of div
    if ( id !== isClicked){
    timerRef.current = setTimeout(() => {
      const divId = document.getElementById(id.toString()+ "-first");
      const divTop = divId?.getBoundingClientRect().top;
      const divLeft = divId?.getBoundingClientRect().left;
      if (divTop !== undefined && divLeft !== undefined){
        //window.scrollTo(0, divTop);
        setPrestaCoord({top: divTop, left: divLeft});        
      }
   
    }, 500);
  }
  }
  
  return (
    <>
    <div className={props.className}>
    <div className='presta-container'>
      <div className="title2">Nos formules</div>
      <div className='travail-photos'>
      {installPhotos.map((aPhoto: PrestaPhotoI)=>(
        <div key={aPhoto.id} className='work-wrapper'>
        <span className='title5-green'>{aPhoto.alt}</span>
        <img key={aPhoto.id} className='travail-photo' src={aPhoto.path} alt={aPhoto.alt}/>
      </div>
      ))}
      
    </div>   
      <div className='prez'>
        Nous vous proposons différentes formules de pension pour répondre au mieux au mode de vie et d'alimentation de votre partenaire.
        <p>N'hésitez pas à nous faire part d'un besoin spécifique, il aura toute notre attention !</p>
      </div>
    
      {prestations.map((aPrestation: PrestaI)=>(
        <div id={aPrestation.id + "-head"}key={aPrestation.id} className='presta-wrapper' onClick={()=>handlePrestaClick(aPrestation.id)} >
          <div className='presta-title'>
            <div className='presta-category'>{aPrestation.category}</div>
            <div className='presta-arrow'>{isClicked === aPrestation.id ? (prestaClass ==='presta-details-hidden' ? '-' : '+'):'+'}</div>
          </div>
          <div className={isClicked === aPrestation.id ? (prestaClass ==='presta-details-hidden' ? 'presta-details' : 'presta-details-hidden'):'presta-details-hidden'}>
            {aPrestation.details.map((aDetail: detailsI)=>(
              <div id={aDetail.id === 0 ? aPrestation.id.toString() + "-first" : ""} className='details-card' key={aDetail.id}>
               <div className='detail-price'>
                {aDetail.price}
                </div>
                <div className='detail-title'>
                {aDetail.title}
                </div>
                <div className='detail-label'>
                {aDetail.label}
                </div>
                
              </div>
            ))}
          </div>
        </div>
        
      ))}
     <div className='travail-photos'>
      {workPhotos.map((aPhoto: PrestaPhotoI)=>(
        <div key={aPhoto.id} className='work-wrapper'>
        <span className='title5'>{aPhoto.alt}</span>
        <img key={aPhoto.id} className='travail-photo' src={aPhoto.path} alt={aPhoto.alt}/>
      </div>
      ))}
      
    </div> 
   
     </div>
     <MentionsFooter className="footer-wrapper"/>
     </div>
     
     </>
  )
}


