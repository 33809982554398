



import poneyBanner from '../images/common/chateauEtAlex2024.jpg';
import { useEffect, useRef } from 'react';

export default function AlaUne({...props}) {
  const observerRef =  useRef<HTMLImageElement>(null);
  
  useEffect(()=>{
    const observer : any = new IntersectionObserver((data)=>{
      if(observerRef.current){
        if (data[0].isIntersecting && observerRef.current){          
            observerRef.current.classList.add("active");
        }else{
          observerRef.current?.classList.remove("active");
        }
      }
    });

    if (observerRef.current){
      observer.observe(observerRef.current)
    }
    return()=>{
      observer.disconnect();
    }
  },[])
  return (
    <><div className='opendoors-block'>
      <h2 className='title1'>À la une ! </h2>
      <p>L'Écurie du château organise un spectacle équestre où se mêlent voltige et monte en amazone en partenariat avec l'écurie Occitane !</p>
      <p>Suivi d'une soirée moules frites et d'un concert popfolk par Avee Project.</p>
      <div className='article-promo'>
      <img ref={observerRef} src={poneyBanner} alt="Spectacle équestre" className='pony-banner'></img>
      
      </div>
      

    </div>
  </>
  )
}
