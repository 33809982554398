import styled from "styled-components";


export const ImgGallery =(props:{path: string | undefined})=>{
    return <StyledImgGallery src={props.path}/>
}

const StyledImgGallery = styled.img`
    width: 20%;
    height: 15em;
    object-fit: cover;   
    border-style: none;
    margin: 0 auto;
   @media screen and (max-width: 850px){
    height: 5em;
    width : 25%;
   }

`