import React, { useEffect, useState } from 'react';
import {Route, Routes} from 'react-router-dom';
import Contact from './Contact';
import Home from './Home';
import Navbar from '../components/Navbar';
import Prestations from './Prestations';
import Actualites from './Actualites';
import Mentions from './Mentions';
import Galerie from './Galerie';





export default function Site ({...props}){
    const [activNavElt, setTheActivNavElt] = useState(-1);
    
    function setActivNavElt(id: number){
        setTheActivNavElt(id);
    }
   
    const siteProps = {...props, setActivNavElt}
        return (
    
            <div className='site'>
            <Navbar activNavElt={activNavElt}/>            
                <Routes>            
                    <Route path='/' element={<Home id={0} className="home-section" {...siteProps}/>}/>
                    <Route path='/formules' element={<Prestations id={1} className="presta-section"  {...siteProps}/>}/>
                    <Route path='/actualites' element={<Actualites id={2} className="actu-section" {...siteProps}/>}/>
                    <Route path='/contact' element={<Contact id={3} className="contact-section" {...siteProps}/>}/>
                    <Route path='/galerie' element={<Galerie id={4} className="gallery-section" {...siteProps}/>}/>
                    <Route path='/mentionslegales' element={<Mentions id={-2} className="mention-section" {...siteProps}/>}/>
                    
                </Routes>
                
            </div>  
                  
            )
        
    }
