import React, { useEffect, useState } from 'react'
import {ReactComponent as EbLogo} from "../svg/logoEnBouyssou.svg"

export default function Mentions({...props}) {
// didmount
useEffect(() => {
  window.scrollTo({top: 0,left: 0, behavior:'smooth'});
  props.setActivNavElt(props.id);
     return()=>{    
       props.setActivNavElt(-1);
     }  

}, []) 
  return (
    <div className='mentions'>
    <h1 className='title2'>Mentions légales et cookies</h1>
    <div className='mentions-infos-container'>
       
      <div className="title5-green">
        Hébergement :
      </div>
      Le site {props.siteName} est hébergé chez Ionos.
      
      <div className="title5-green">
        Conception, réalisation et maintenance du site :
      </div>
      <div className="eb-wrapper">Aline BRAND - <EbLogo className="eb-logo"/> <span className="eb-span">La Toile d'En Bouyssou </span></div>
      12 chemin d'en Bouyssou, 81220 Viterbe
      <div className="title5-green">
      Éditeur du site
      </div>
      Écurie du château 1925 route des Châteaux, 31340 Villematier
      <div className='carac-perso'>
      <div className="title5-green">
      Droits d’auteurs et propriété intellectuelle
      </div>
      Selon la loi du 11 mars 1957 (art.41) et du code de la propriété intellectuelle du 1er juillet 1992, le site {props.siteName} 
       &nbsp; et son contenu constituent une œuvre protégée en France par le Code de la Propriété intellectuelle, et à l’étranger par les conventions internationales en vigueur sur le droit d’auteur.
      </div>
      <div className="title5-green">
        Cookies 
      </div>
      Le site {props.siteName} n'utilise pas de cookies publicitaires. 
      Google maps et facebook utilisent des cookies intersites. 
      Vous pouvez les désactiver sans nuire à votre navigation.
      <div className="title5-green">
        Informations personnelles 
      </div>
      Le site {props.siteName} ne collecte pas d'informations personnelles.
    </div>
    </div>
  )
}
