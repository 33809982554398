import React, { useEffect, useState } from 'react'
import  {FbEcurieChateau}  from '../components/FbEcurieChateau'
import GMap from '../components/GMap'
import MentionsFooter from '../components/MentionsFooter';
import { MapComponent } from '../components/GMapsReact';





export default function Contact({...props}) {
  

  const [fbScript, setFbScript] = useState<HTMLScriptElement>();

  useEffect(() => {
    window.scrollTo({top: 0,left: 0, behavior:'smooth'});
      const fbScript = document.createElement('script');
      fbScript.src = "https://connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v14.0";
      fbScript.async = true;
      fbScript.nonce = "fxHrIspc";
      fbScript.defer = true;
      fbScript.crossOrigin = "anonymous";
      //document.cookie="__Host-name=value; Secure; Path=/; SameSite=None; Partitioned;"
      if (fbScript !== undefined)
      setFbScript(fbScript);
      document.body.appendChild(fbScript);
      props.setActivNavElt(props.id);  
  
     return()=>{
       document.body.removeChild(fbScript);
       props.setActivNavElt(-1);
     }  
  
   
  }, [])

 
  return (
    <>
   
    <div className={props.className}>
      <div className='contact-wrapper'>
    <h1 className="title2">Nous contacter</h1>
    <div className="title1">
      Par téléphone
    </div>
    <div className='contact-span'>      
      <p>{props.tel}</p>
    </div>
    <div className="title1">
      Venir à l'Écurie
    </div>
    <div className='contact-span'>
      <p>1925 route des Châteaux</p>
      <p> 31340 Villematier</p>
    </div>
    <div className="title1">
      Par internet, notre site web
    </div>
    <div className='contact-span'>
      <p><a href='https://ecurieduchateau.fr'>
        {props.siteName}</a></p>
     
    </div>
    
    
    <div className='contact-span'>
    <div className="title1">
      Nous localiser
    </div>
    <div className='contact-span'>
      <MapComponent/>
    
    </div>
    <div id="fb-root"></div>
    <FbEcurieChateau fbScript={fbScript}/>
    </div>
    </div>
    <MentionsFooter className="footer-wrapper"/>
    </div>
 
    </>
    )
  }
