import React, { useEffect, useRef } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";

const GMAPKEY="AIzaSyC-7Pc022Y1dxJ-AzrKZQxQRDMyN9i6ZaA";

const DEFAULT_CENTER = { lat: 43.81281059173789, lng: 1.474612606178438 };
const DEFAULT_ZOOM = 14;


export const GoogleMaps = (
  {
    locations,
    className,
  }: {
    locations: ReadonlyArray<google.maps.LatLngLiteral>;
    className?: string;
  }
) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Display the map
    if (ref.current) {
     const map = new window.google.maps.Map(ref.current, {
        center: DEFAULT_CENTER,
        zoom: DEFAULT_ZOOM,
      });
    
    // Display the marker
    addSingleMarkers({ locations, map });
  }
  }, [ref, locations]);
  const goThere = `https://maps.app.goo.gl/6BJbVoEdQbmYoXVg8`;
  return (
    <a href={goThere}>
      <div
        ref={ref}
        style={{ width: "80%", height: "300px", margin: "0 auto", display:"flex" }}
      />
    </a>
  );
};


export const GoogleMapsWrapper = ({children}:{children: React.ReactNode;}) => {
  // Ideally we want the apiKey to be fetch from an environment variable  
  const apiKey = GMAPKEY;
  if (!apiKey) {
    return <div>Cannot display the map: google maps api key missing</div>;
  }

  return <Wrapper apiKey={apiKey}>{children}</Wrapper>;
};

export const MARKERLOCATIONS = [
  { lat: 43.810755396725604, lng: 1.4731167228121935 },
  
];
export const MapComponent = () => (
  <GoogleMapsWrapper>
    <GoogleMaps locations={MARKERLOCATIONS}/>
  </GoogleMapsWrapper>
);

// markers
export const addSingleMarkers = ({
  locations,
  map}: 
  {
  locations: ReadonlyArray<google.maps.LatLngLiteral>;
  map: google.maps.Map | null | undefined;
}) =>
  locations.map (position => new google.maps.Marker({
        position,
        map
      })
      
  );