import React, { Fragment } from 'react'
import {Link} from 'react-router-dom'
import {NavLink} from 'react-router-dom';
import { navElts } from '../data';
import logo from '../images/common/logo.png';

interface NavEltsI{
  id: number,
  label: string,
  path: string
}

export default function MentionsFooter({...props}) {
  return (
    <>
    
    <div className={props.className}>
      
    <div className='mentions-container'>
    <img src={logo} alt="Logo Écurie du Château" className='logo-footer'></img>
      <div className='mentions-wrapper'>
        <NavLink to="/mentionslegales"> 
        <div className="navelt-wrapper"><span className="mentionslst-span">Mentions légales</span> </div>
        </NavLink>      
         
          {navElts.map((navElt: NavEltsI)=>(
            <div className="navelt-wrapper" key={navElt.id}>
            <span className='mentionslst-span'>|</span>
            <NavLink className="mentionslst-span" key={navElt.id} to={navElt.path}>
               {navElt.label}
              </NavLink> 
              </div>
          ))}
     
      </div>
      </div>
   
    </div>
   
</>
  )
}


