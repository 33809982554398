
import ScrollingCarousel from '../components/ScrollingCarousel'
import {actuData} from '../data'
import { useEffect } from 'react'
import flyer from '../images/common/flyer_Ingrid.png'
import flyerInfos from '../images/common/flyer_Ingrid_infos.png'
import Brochure from '../components/Brochure'
import FollowPoints from '../components/FollowPoints'
import MentionsFooter from '../components/MentionsFooter'
import brochure from "../images/common/comAnimale2511.png";
import brochureFeb01 from "../images/common/febTraining_flyer01.jpg";
import brochureFeb02 from "../images/common/febTraining_flyer02.jpg";
import brochurePdf from "../images/common/comAnimaleInscription.pdf";
import brochurePdfFeb from "../images/common/febTraining.pdf";
import AlaUne from '../components/aLaUne'

interface ActuDataI{
  id: number,
  date: string,
  description: string,
  background: string | null | undefined,
  alt: string | null | undefined
}

export default function Actualites({...props}) {

  // didmount
useEffect(() => {
  window.scrollTo({top: 0,left: 0, behavior:'smooth'});
  props.setActivNavElt(props.id);
  return()=>{
    props.setActivNavElt(-1);
  }
}, []) 
  
  return (
    <><div className={props.className}>
      <div className="title2">Les actualités</div>
      <AlaUne/>
      <div className='actu-wrapper'>
      <h2 className="title1">Bienvenue chez vous ! Ça s'est passé à l'écurie...</h2>
      <div className='scrolling-position'>
        <ScrollingCarousel blockData={actuData} />
      </div>
      </div>
      {/* <div className='actu-wrapper'>
      <FollowPoints/>      
      
    <h2 className='title1'>Ça s'est passé à l'Écurie</h2><div className='prez-first'>
    <div className='prez-prime-actu'>     
        <div className='prez-second'>
          <div className='img-block-actu'>
            <img className='flyer-com' src={brochureFeb01} alt="EquiLys Ingrid stage equifeel et longues rênes" />
          </div>
          <div className='prez-first'>
            <div className='ens'>
              <span>Stage Étho, embuches et longues rênes avec </span>
              <p className='span1'>Ingrid EquiLys</p>
            </div>            
          </div>
          
        </div>
        <Brochure brochure={brochureFeb02} docLink={brochurePdfFeb} title="Stage Equifeel et longues rênes" titleClassName="title4"/>
      </div>
    <div className='prez-prime-actu'>
        <div className='prez-second'>
          <div className='img-block-home'>
            <img className='flyer-ingrid' src={flyer} alt="EquiLys Ingrid stage equifeel" />
          </div>
          <div className='prez-first'>
            <div className='ens'>
              <span>Stage Étho et embuches avec </span>
              <p className='span1'>Ingrid EquiLys</p>
            </div>
            <img className='flyer-ingrid' src={flyerInfos} alt="EquiLys Ingrid stage equifeel" />
          </div>
        </div>

      </div>
    <Brochure brochure={brochure} docLink={brochurePdf} title="Stage d'initiation à la communication animale" titleClassName="title2"/>
      </div>
      </div> */}
      <MentionsFooter className="footer-wrapper"/>
      </div>
     
      </>
    )
  }


