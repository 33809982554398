import './style/App.css';
import Site from './pages/Site';
import { BrowserRouter } from 'react-router-dom';


declare global {
  interface Window {
    __REACT_DEVTOOLS_GLOBAL_HOOK__: any;
  }
}


const props = {siteName : "ecurieduchateau.fr", tel : "06 17 81 29 98"}


function App() {
  const disableReactDevTools=()=>{
       
    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
      for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? ()=>{} : null;
      }
    }
  }
  const disableConsoleLog=()=>{
    console.log = function () {};
  }
// disable console.log and react devtools if prod
if (!window.location.href.includes("http://localhost")){
    disableReactDevTools();
    disableConsoleLog();
}
  return (
    <div className="App">
      <BrowserRouter>      
        <Site {...props} />        
     </BrowserRouter>
     
    </div>
  );
}

export default App;
