
import { structures } from '../data'

interface iStruct{
    id: number,
    title: string,
    label: string,
    img: string | undefined | null,
    alt: string | undefined | null
}

export default function OverlayPrez() {
  return (
    <>
    <div className='title2'>
      <span >Nos Installations</span>
    </div>

    <div className='install-container'>    
      {structures.map((aStrucElt: iStruct) => (
        <div key={aStrucElt.id} className='overlay-container'>
          <div className='title1'>
            <span>{aStrucElt.title}</span>
          </div>
          <div className='overlay-wrapper'>
          
          <div className='overlay-label'>
              {aStrucElt.label}
          </div>
          <div className='overlay-img-container'>
          {aStrucElt.alt !== null && aStrucElt.alt !== undefined && aStrucElt.img !== null && aStrucElt.img !== undefined ? <img className='overlay-img' src={aStrucElt.img} alt={aStrucElt.alt} /> :<div></div>}
            
          </div>
          </div>
        </div>
      ))}
    </div>
    </>
  )
}
