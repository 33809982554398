
import { ActuSliding } from '../common/StyledComponents'
import { actualities } from '../data'
import { ScrollNav } from './ScrollNav'

interface iActu{
    id: number,
    date: string,
    label: string
}

export default function Actualities() {
  return (
    <div className='actu-container'>
      <div className='actu-title'>Actualités</div>
    <ActuSliding nbOfActus={actualities.length}>
        <div className='actu-position'>
        {actualities.map((anActu: iActu)=>(            
              <div key={anActu.id} className='actu-article'>
                  {anActu.date} : {anActu.label}
            </div>

           
        ))}
        
    </div>
    </ActuSliding>
    </div>
  )
}
