
import { useEffect } from "react";
import { ImgGallery } from "../components/ImgGallery";
import MentionsFooter from "../components/MentionsFooter";
import '../style/App.css';

const myImports = require.context("../../public/Gallery/", true, /\.png|jpe?g|JPE?G$/);
const path = myImports.keys().map((myImport: any)=> myImport.replace("./", "Gallery/"));



export default function Galerie({...props}) {  
// didmount
useEffect(() => {
    window.scrollTo({top: 0,left: 0, behavior:'smooth'});
    props.setActivNavElt(props.id);
     return()=>{    
       props.setActivNavElt(-1);
     }  
   }, []) 
    return(
<>
       

             <div className={props.className}>
             <h1 className="title2">L'Écurie en photos</h1>
                <div className="gallery">
                    {path.map((anImport: string | undefined, key)=>
                    <ImgGallery key={key} path={anImport}/>)}
                    </div>
                    <MentionsFooter className="footer-wrapper"/>
            </div>
           
        </>
    )
}