import styled, {keyframes} from "styled-components";

interface NavLetterI{
    frequence: string | undefined,
    launchAnim: boolean
}
interface SlideInI{
  nbOfActus: number;
}
interface OverlayLabelI{
  number : number;
}
interface StyledAppearingCard{
  canAppear: boolean;
}
interface PriorBlock{
  priorityId: number;
  dataId: number;
  numberOfItems: number;
}
interface ActivBoolI{
  isActiv: boolean;
  launchAnim : boolean;
}

  const sectionsHight = '3em';
  const mediumFontSize = '1em';
  const mediumPadding = '1.5rem';
  const appMainColor = '#7caf62';
  const appLightColor = 'rgba(255, 255, 255, 0.8)';
const greenGrandient = `background-image:
linear-gradient(217deg, rgba(153, 255, 0, 0.8), rgba(255,0,0,0) 70.71%),
linear-gradient(127deg, rgba(94, 255, 0, 0.8), rgba(0,255,0,0) 70.71%),
linear-gradient(336deg, rgba(208, 255, 0, 0.8), rgba(0,0,255,0) 70.71%);`
  const fontMedColor = '#7caf62';
  const fontMainColor = 'rgb(92, 92, 92)';

  const navBckgColor = 'rgba(255, 255, 255, 0.8)';
  const imgEffectDarkColor = 'rgba(0, 0, 0, 0.5)';

export const rotating = keyframes `
0% {
    opacity: 1;
    transform: rotateX(0deg);
  }
  50%{
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: rotateX(720deg);
  }
`;
export const slideOut = keyframes `
0% {      
  transform: translateX(0%);
}   
100% {       
    transform: translateX(100%);
}

`

export const slidein = (props: number)=> keyframes`
  0% {   
  transform: translateX(100vw); 
  } 
  

  100% { 
    
    transform: translateX(${(props*(-50))}vw);
  
  }
`
export const slideinPhone = (props: number)=> keyframes`
  0% {   
  transform: translateX(100vw); 
  } 
  

  100% { 
    
    transform: translateX(${(props*2*(-100))}vw);
  
  }
`

export const ActuSliding = styled.div<SlideInI>`
  display: inline-flex;
    width: ${(props:SlideInI)=>props.nbOfActus*100}vw;
    height: 100%;
    left: 0;
    text-align: left;
    transform-origin: left;
    -webkit-animation: 25s linear infinite ${(props:SlideInI)=>slidein(props.nbOfActus)};
            animation: 25s linear infinite ${(props:SlideInI)=>slidein(props.nbOfActus)};
    justify-content: flex-start;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    padding: 0;
    align-content: center;
 @media screen and (max-width: 850px){
  -webkit-animation: 25s linear infinite ${(props:SlideInI)=>slideinPhone(props.nbOfActus)};
            animation: 25s linear infinite ${(props:SlideInI)=>slideinPhone(props.nbOfActus)};
 }

`

export const MovingLoading = styled.div`
    animation: .5s linear 1s infinite alternate ${slideOut}; 
    text-align: left;
    color: var(--appMainColor);
    font-size: 2em;
    padding: 0.1em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    :nth-child(2n){
        animation: .5s linear .5s infinite alternate ${slideOut}; 
    }
`

export const NavLetter = styled.div<NavLetterI>`
  display: inline-flex;
  animation: ${(props:NavLetterI)=>props.launchAnim && rotating} ${(props: NavLetterI)=>props.frequence}s linear;
  -webkit-animation: ${(props:NavLetterI)=>props.launchAnim && rotating} ${(props: NavLetterI)=>props.frequence}s linear;
`;
export const PriorityBlock = styled.div<PriorBlock>`
text-align: center;
position: absolute;
 width: 80%;
 height: 80%;
 max-width: 100vw;
 margin: 0 auto;
 /* background-color: #FAF2EA; */
 background-color: rgb(220, 220, 220); 
 transform-style: preserve-3d;
 transform: scaleY(${(props: PriorBlock)=>props.priorityId===0 || props.priorityId === 1  || props.priorityId === 2 ?'1':1/props.priorityId*3}) translateX(${(props: PriorBlock)=>props.priorityId*10}%) rotateX(${(props: PriorBlock)=>props.priorityId*5}deg);
 -webkit-transform: scaleY(${(props: PriorBlock)=>props.priorityId===0 || props.priorityId === 1  || props.priorityId === 2 ?'1':1/props.priorityId*3}) translateX(${(props: PriorBlock)=>props.priorityId*10}%) rotateX(${(props: PriorBlock)=>props.priorityId*5}deg);
 perspective-origin: center center;
 transition: transform, left, z-index 0.8s, 0.8s, 0.1s ease-in; 
 -webkit-transition: transform, left, z-index 0.8s, 0.8s, 0.1s ease-in; 
 z-index : ${(props: PriorBlock)=>props.priorityId*(-100)}; 
 border-radius: 10px;
 display: block;
 opacity: ${(props: PriorBlock)=>props.priorityId<4 ? '1' : '0'};
 background-color: ${(props: PriorBlock)=>props.priorityId%2===0 ? 'rgb(167, 167, 167)' : '#fffefcce'};
 
 
 /*IPHONE 4*/
 @media only screen 
    and (device-width : 375px) 
    and (device-height : 667px) 
    and (-webkit-device-pixel-ratio : 2) { 
      -webkit-transform: translateZ(${(props:PriorBlock)=>props.priorityId*(-1)}px) scaleY(${(props: PriorBlock)=>props.priorityId===0 || props.priorityId === 1  || props.priorityId === 2 ?'1':1/props.priorityId*3}) translateX(${(props: PriorBlock)=>props.priorityId*10}%) ; 
    }
 @media only screen and (min-device-width : 375px) and (max-device-width : 667px) { 
  -webkit-transform: translateZ(${(props:PriorBlock)=>props.priorityId*(-1)}px) scaleY(${(props: PriorBlock)=>props.priorityId===0 || props.priorityId === 1  || props.priorityId === 2 ?'1':1/props.priorityId*3}) translateX(${(props: PriorBlock)=>props.priorityId*10}%); 
 }
 @media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5){
  -webkit-transform: translateZ(${(props:PriorBlock)=>props.priorityId*(-1)}px) scaleY(${(props: PriorBlock)=>props.priorityId===0 || props.priorityId === 1  || props.priorityId === 2 ?'1':1/props.priorityId*3}) translateX(${(props: PriorBlock)=>props.priorityId*10}%); 
}
@media screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2){
  -webkit-transform: translateZ(${(props:PriorBlock)=>props.priorityId*(-1)}px) scaleY(${(props: PriorBlock)=>props.priorityId===0 || props.priorityId === 1  || props.priorityId === 2 ?'1':1/props.priorityId*3}) translateX(${(props: PriorBlock)=>props.priorityId*10}%); 
}
`
export const BlockContent = styled.div<StyledAppearingCard>`
 
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100000;
    background-color: rgba(237, 67, 83, 0.8);
   /*  background-color: rgba(12,12,12,0.8); */
    color:rgba(245, 235, 237, 1);
    height: 5em;
    width: inherit;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 1% 1% 10px 10px;

  animation: 1s ${(props: StyledAppearingCard)=>props.canAppear === true ? birth : "none;" }; 
  transform-origin: left;
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
 
  margin: 0 auto;
  animation-duration: 1s;
  animation-iteration-count: 1;
 
  opacity: ${(props: StyledAppearingCard)=>props.canAppear === true ? "1" : "0;" }; 
 
@media screen and (max-width: 992px){
    height: 8em;
}
`
export const ScrollNavElt = styled.div<ActivBoolI>`
  width: 2em;
  height: 0.15em;
  margin: 1em;
  border-radius: 3px;
  border:#7caf62 1px solid;  
  background-image: ${(props: ActivBoolI)=>props.isActiv === true ? "linear-gradient(217deg, rgba(153, 255, 0, 0.8), rgba(255,0,0,0) 70.71%), linear-gradient(127deg, rgba(94, 255, 0, 0.8), rgba(0,255,0,0) 70.71%),linear-gradient(336deg, rgba(208, 255, 0, 0.8), rgba(0,0,255,0) 70.71%)" : "none" };
  transform-origin: left;
  animation: ${(props:ActivBoolI)=>props.launchAnim ? "animateGradient 1s linear" : "none" };
  -webkit-animation: ${(props:ActivBoolI)=>props.launchAnim ? "animateGradient 1s linear" : "none" };
`
export const animateGradient = keyframes `
    0% {      
      background-image: none;
    }   
    100% {
      background-image:
      linear-gradient(217deg, rgba(153, 255, 0, 0.8), rgba(255,0,0,0) 70.71%),
      linear-gradient(127deg, rgba(94, 255, 0, 0.8), rgba(0,255,0,0) 70.71%),
      linear-gradient(336deg, rgba(208, 255, 0, 0.8), rgba(0,0,255,0) 70.71%);
    }
`

export const birth = keyframes `
    0% {      
      transform: scaleX(0%);
      opacity: 0;
    }   
    100% {       
        transform: scaleX(100%);
        opacity: 1;
    }
   
    `
export const OverlayLabel = styled.div<OverlayLabelI>`
  position: absolute;
  display: block;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
  padding: 0.5em;
  display: block;
  text-align: justify;
  color: ${appLightColor};
  background-color: ${imgEffectDarkColor};
  top: ${(props:OverlayLabelI)=> props.number%2 === 0 ? '4em':'10em'};
  left: 20em;
  @media (max-width: 800px) {
    left: 0;
  } 

  `