import { ScrollNavElt } from "../common/StyledComponents"

interface ScrollNavI{
    elements: {id: number, isActiv: boolean, launchAnim: boolean}[]
}

export const ScrollNav = (props : ScrollNavI) =>(
    <div className="scrollnav">
        {props.elements.map((anElt: {id: number, launchAnim: boolean, isActiv: boolean})=>(
            <ScrollNavElt launchAnim={anElt.launchAnim} isActiv={anElt.isActiv} key={anElt.id}>
                &nbsp;
            </ScrollNavElt>
        ))}
    </div>
)