import { useEffect, useRef, Children } from 'react'


function Interceptor({...props}) {
    
    const interceptorRef = useRef<HTMLDivElement>(null)

    useEffect(()=>{
        const theInterceptor = new IntersectionObserver((observedElt)=>{
            if(interceptorRef.current){
            if(observedElt[0].isIntersecting){
                interceptorRef.current.classList.add("active");
            }else{
                interceptorRef.current.classList.remove("active");
            }
        }
        })
        if (interceptorRef.current)
            theInterceptor.observe(interceptorRef.current);
    }, [])
    return (
        <div className={props.className} ref={interceptorRef}> 
         {props.children}
        </div>
    )
}

export default Interceptor
