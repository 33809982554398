import { useEffect, useRef, useState } from 'react'
import {NavLink} from 'react-router-dom';
import { NavLetter } from '../common/StyledComponents';
import {ReactComponent as Burger} from '../svg/burger.svg';
import {ReactComponent as Logo} from '../svg/logo.svg';
import {ReactComponent as Cross} from '../svg/burger_cross.svg';
import {navElts} from "../data";

interface NavEltsI{
  id: number,
  label: string[],
  path: string  
}
class NavElts{
  id: number;
  label: string[];
  path: string;
  constructor(pId: number, pLabel: string[], pPath: string){
    this.id = pId;
    this.label = pLabel;
    this.path = pPath;
  }
}
interface DataNavEltsI{
  id: number,
  label: string,
  path: string
}

export default function Navbar({...props}) {

const [launchAnim, setLaunchAnim] = useState(false);

const [burgerIsClicked, setBurgerIsClicked] = useState(false);
const [NavEltsState, setNavEltsState] = useState<NavEltsI[]>();
const timerRef = useRef<any>(null);
const timerBurgerCross = useRef<any>(null);

useEffect(() => {
 timerRef.current = setTimeout(() => {
  setLaunchAnim(launchAnim === false ? true : false);  
 }, 10000);
 

  return () => {
   clearTimeout(timerRef.current);
  }
})
// didmount
useEffect(() => { 
  setLaunchAnim(true);
  formatNavLetters(navElts);
   return () => {
    clearTimeout(timerRef.current);
   }
 }, [])
 
function calculateFrequenceFromKey(nb: number){
  let u = 0.5;
  const r = 0.05;
 let myTable = [];
    for (let index = 0; index <= nb; index++) {
      u = u + r;
      myTable.push(u);
    }
    return myTable;
  
}

function formatNavLetters(dataNavElts: DataNavEltsI[]){
  let navElts: NavEltsI[];
  navElts = dataNavElts.map((anElt: DataNavEltsI)=>{  
    let splitedLabel =  anElt.label.split("");
    // Nos Formules
    if (anElt.id === 1){
      splitedLabel = ["N", "o", "s"+" " + "F","o","r","m","u","l","e","s"];
    }
    const navElt = new NavElts(anElt.id, splitedLabel, anElt.path);
    
    return navElt;
  })
  setNavEltsState(navElts);
  console.log('navElts : ', navElts);
}
  
 /*  const navElts = [
    {id: 0, label: ["A","c","c","u","e","i","l"], path: "/"},
    {id: 1, label: ["N", "o", "s"+" " + "F","o","r","m","u","l","e","s"], path: "/formules"},
    {id: 2, label: ["A","c","t","u","a","l","i","t", "é", "s"], path: "/actualites"},
    {id: 3, label: ["C","o","n","t","a","c","t"], path: "/contact"},
    {id: 4, label: ["G","a","l","e","r","i","e"], path: "/galerie"} 
  
  ] */
const [wrapperClass, setWrapperClass] = useState<string>('navbar-wrapper');
const [crossClass, setCrossClass] = useState<string>("burger-cross");


  const handlePhoneMenu=()=> {
    setWrapperClass(wrapperClass === "navbar-wrapper" ? "opened-navbar" : "navbar-wrapper");
    setCrossClass("burger-cross-animated");
    timerBurgerCross.current = setTimeout(()=>{
      // reinit burger click for cross anim
      setCrossClass("burger-cross");
     }, 1500)
  }
  

  return (
    <div className='navbar-container'>
           
        <h2 className='h2-nav'> Écurie du château | Villematier</h2>
        <Logo className='logo-menu'/>
        <ul className={wrapperClass}>
        <Burger 
        onClick={()=>{
          handlePhoneMenu()
          setBurgerIsClicked(burgerIsClicked ? false : true)
        
        }} 
        className={burgerIsClicked ? 'menu-tel-nopop' : "menu-tel"}/>      
         <Cross 
         className= {crossClass}
         onClick={(e: React.MouseEvent)=>
          {handlePhoneMenu()
          setBurgerIsClicked(burgerIsClicked ? false : true)
          }        
        }
         />
          {NavEltsState && NavEltsState.map((anElt: NavEltsI)=>(
            <NavLink 
            onClick={()=>{              
              handlePhoneMenu()
              setBurgerIsClicked(burgerIsClicked ? false : true)
            }}
            key={anElt.id}            
            to={anElt.path}
            className={props.activNavElt === anElt.id ? "li-menu-activ" : "li-menu"}
            >
              {anElt.label.map((aLabel: string, key: number)=>(
                <NavLetter 
                frequence={calculateFrequenceFromKey(anElt.label.length)[key].toString()}
                key={key}
                launchAnim={props.activNavElt === anElt.id}
                >
                  {aLabel}                
                </NavLetter>
              ))}
            </NavLink>
          ))}

        </ul>
        
        
    </div>
  )
}


