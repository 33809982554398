import { useEffect } from "react";
import { ReactComponent as Facebook} from "../svg/Facebook.svg";


export function FbEcurieChateau ({...props}){
    // reload window for async fbScript
    useEffect(() => {
        let isLaunched = false;
        if(props.fbScript !== undefined && !isLaunched){
            window.location.reload();
            isLaunched = true;
        }
     
    }, [])
    
    return (

    <>
   
    <div className="fb-titlediv"><span>Rejoignez-nous sur facebook</span>
        <Facebook style={{ width: '250px', height: '250px' }} />
    </div>        
        {props.fbScript !== undefined && <div className="fb-page" data-href="https://www.facebook.com/profile.php?id=100084447831999" data-tabs="timeline" data-width="" data-height="50" data-small-header="false" data-adapt-container-width="true" data-lazy="true" data-hide-cover="false" data-show-facepile="true">
        
                <blockquote 
                cite="https://www.facebook.com/profile.php?id=100084447831999" 
                className="fb-xfbml-parse-ignore">
                <a className="a-contest" href="https://www.facebook.com/profile.php?id=100084447831999">
                        Écurie du Château
                    </a>
                </blockquote>
            </div>}
    </>
)}