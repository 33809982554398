import box from "./images/accueil/stable.png";
import carriere from "./images/accueil/carriere.jpg";
import clubHouse from "./images/accueil/club-house.jpg";
import rond from "./images/accueil/vueDroneRond.jpg";
import affiche from "./images/actualites/affiche_box.jpg";
import ET from "./images/actualites/ET_mange.jpg";
import carriereselfie from "./images/actualites/carriere.jpg";
import article from "./images/actualites/articleLaDepeche.png";
import airePansage from "./images/accueil/airePansage.jpg";
import pres from "./images/accueil/pres.png";
import ComAnimale from "./images/common/comAnimale2511.png";
import lesPres from "./images/actualites/pres.jpg";

interface ActuData{
    id: number,
    date: string,
    description: string,
    background: string | null | undefined,
    alt: string | null | undefined,
    actuClass?: string;
}
interface ITrainingData{
    id: number,
    title?: string,
    detail: string | string[],
    price?: string | string[],
    image?: string | null | undefined,
    alt?: string | null | undefined
}

interface iStruct{
    id: number,
    title: string,
    label: string,
    img: string | undefined | null,
    alt: string | undefined | null
}

export const navElts = [
    {id: 0, label: "Accueil", path: "/"},
    {id: 1, label: "Nos formules", path: "/formules"},
    {id: 2, label: "Actualités", path: "/actualites"},
    {id: 3, label: "Contact", path: "/contact"},
    {id: 4, label: "Galerie", path: "/galerie"}
  ]

export const actuData: ActuData[] = [
    {id: 0, date: "Le 15 septembre 2023", description: "Un an ! ", background: lesPres, alt: "un an !", actuClass: 'img-block'},
    {id: 1, date: "Le 25 février 2023", description: "Stage etho et embûches / longues rênes", background: null, alt: "Stage etho et embuches, longues rênes", actuClass: 'img-block'},
    {id: 2, date: "Le 1er septembre 2022", description: "Ouverture de l'Écurie du château ! ", background: ET, alt: "Affection", actuClass: 'img-block-actu'},
    {id: 3, date: "Septembre 2022", description: "Grand nettoyage", background: affiche, alt: "Affiche écuries", actuClass: 'img-block-actu'},
    {id: 4, date: "Le 1er octobre 2022", description: "Réfection de la carrière", background: carriereselfie, alt: "carrière", actuClass: 'img-block-actu'},
    {id: 5, date: "Le 8 octobre 2022", description: "Inauguration de l'Écurie du château", background: article, alt:"La Dépêche, inauguration", actuClass: 'img-block'},
    {id: 6, date: "Samedi 22 octobre 2022", description: "Stage Equifeel et éthologie", background: null, alt: "Flyer Equifeel", actuClass: 'img-block'},
    {id: 7, date: "Samedi 19 novembre 2022", description: "Initiation Communication Animale", background: ComAnimale, alt: "Flyer", actuClass: 'img-block'},
    
]
export const trainingData: ITrainingData[] = [
    {id: 0, title: "Stage", detail: "La demi journée :", price: "40€"},
    {id: 1, detail: "La journée :", price: "70€"},
    {id: 2, title: "Location structure pour cavaliers extérieurs ", detail: ["La journée :"], price: ["10€"]},
    {id: 3, detail: ["La demi journée :"], price: ["5€"]},
]
export const trainingFeb: ITrainingData[] = [
    {id: 0, title: "Stage embûches et longues rênes", detail: ["Horaire : 9h-12h"]},
    {id: 1, title: "Travail spécifique sur problématiques particulières (embarquement...)", detail:["Horaires :","14h-17h | ", "14h-15h | ", "15h-16h | ", "16h-17h"]},
    {id: 2, title: "Tarifs ", detail: ["La journée : 70€ | ", "La demi journée : 40€ | ", "L'heure : 30€"]},
    {id: 3, title: "Location structure pour cavaliers extérieurs ", detail: ["La journée : 10€ | ", "La demi journée : 5€"]},
    {id: 4, title: "Location d'un poney possible ", detail: ["10€"]}
    
]


export const _actualities = [ 
    {id: 8, date: "Le 1er septembre 2022", label: "Ouverture de l'Écurie du château ! "},
    {id: 7, date: "Septembre 2022", label: "Nettoyage des box, du rond de longe et de la carrière"},
    {id: 6, date: "Le 1er octobre 2022", label: "Réfection de la carrière"},
    {id: 5, date: "Le 8 octobre 2022", label: "Inauguration de l'Écurie du château !"},
    {id: 4, date: "Le 22 octobre 2022", label: "Stage Equifeel et éthologie"},
    {id: 3, date: "Le 19 novembre 2022", label: "Initiation à la Communication Animale"},
    {id: 2, date: "Le 25 février 2023", label: "Stage etho et embûches / longues rênes"},
    {id: 1, date: "Le 15 septembre 2023", label: "L'Écurie du château souffle sa première bougie !"},
    {id: 0, date: "Le 13 juillet 2024", label: "Spectacle équestre et soirée moules frites !"}
 
]
export const actualities = [ 

    {id: 0, date: "Le 13 juillet 2024", label: "Spectacle équestre et soirée moules frites !"},
    {id: 1, date: "Le 15 septembre 2023", label: "L'Écurie du château souffle sa première bougie !"},
    {id: 2, date: "Le 25 février 2023", label: "Stage etho et embûches / longues rênes"},
    {id: 3, date: "Le 19 novembre 2022", label: "Initiation à la Communication Animale"},
    {id: 4, date: "Le 22 octobre 2022", label: "Stage Equifeel et éthologie"},
    {id: 5, date: "Le 8 octobre 2022", label: "Inauguration de l'Écurie du château !"},
    {id: 6, date: "Le 1er octobre 2022", label: "Réfection de la carrière"},
    {id: 7, date: "Septembre 2022", label: "Nettoyage des box, du rond de longe et de la carrière"},
    {id: 8, date: "Le 1er septembre 2022", label: "Ouverture de l'Écurie du château ! "}
]

export const structures : iStruct[] = [
    {id: 0, title: "L'écurie", label : "12 box de 3 mètres sur 3 accueillent nos pensionnaires de la formule box | paddock. Les box sont curés et repaillés pour leur plus grand confort", img: box, alt: "Box tout confort"},
    {id: 1, title: "Les Prés et paddocks", label : "Les pensionnaires pré | box sont accueillis dans les prés par groupes de 2 ou 4, suivant leurs affinités. En formule pré | paddock, les chevaux et poneys sont répartis en paddocks individuels", img: pres, alt: "Prés et Paddocks"},
    {id: 2, title: "La Carrière", label: "Pour travailler, nous vous proposons une carrière lettrée et éclairée de 80 x 30 mètres. Des obstacles sont mis à votre disposition pour l'entraînement au CSO. La carrière est également disponible en location d'infrastructures", img: carriere, alt: "Grande carrière"},
    {id: 3, title: "Le Rond de longe", label: "À votre disposition, un rond de longe fermé de 18 mètres de diamètre", img: rond, alt: "Rond de longe"},
    {id: 4, title: "Le Club house", label: "Notre club house vous accueille pour une petite pause. Venez vous retrouver entre propriétaires pour partager vos impressions autour d'une boisson", img : clubHouse, alt: "Club house"},
    {id: 5, title: "L'Aire de pansage", label: "Notre aire de pansage permet de vous préparer en toute sérénité. À proximité, la douche murée et toute équipée est très appréciée par tous les temps", img: airePansage, alt: "Aire de pansage"}
    
    
]

export const prestations = [
    {id: 0 , category : "Pensions box | paddock", details : [
        {id: 0, title: "Pension Box | Paddock", label : "2 rations de grain et de foin par jour. Sortie quotidienne en paddock individuel. Accès aux structures", price: "365€"},
        {id: 1, title: "Pension box | Paddock & Travail", label : "2 rations de grain et de foin par jour. Sortie quotidienne en paddock individuel. Accès aux structures. 2 séances de travail du cheval par semaine", price: "480€"}
        
       

    ]},
    {id: 1 , category : "Pensions pré", details : [
        {id: 0, title: "Pension pré", label : "Les chevaux se partagent un pré à deux ou quatre suivant les affinités de chacun. Foin à volonté. Accès aux structures", price: "195€"}, 
        {id: 1, title: "Pension pré & Rations", label : "Les chevaux se partagent un pré à deux ou quatre suivant les affinités de chacun. Foin à volonté. 2 rations de grain par jour. Accès aux structures", price: "240€"}
    ]},
    {id: 2 , category : "Travail du cheval", details : [
        {id: 0, title: "Travail monté sur le plat", label : "Travail sur le plat, tarif à la séance", price: "25€"},
        {id: 1, title: "Travail monté sur le plat 4 séances par mois", label : "Travail sur le plat, 4 séances par mois", price: "80€"},
        {id: 2, title: "Travail monté sur le plat 8 séances par mois", label : "Travail sur le plat, 8 séances par mois", price: "140€"},
        {id: 3, title: "Travail monté à l'obstacle, préparation CSO", label: "Travail à l'obstacle sur rendez-vous, tarif à la séance", price: "35€"},
        {id: 4, title: "Travail en longe", label: "Travail à la longe, avec ou sans enrênement. Tarif à la séance", price: "15€"}

    ]},
    {id: 3 , category : "Location des structures", details : [
        {id: 0, title: "Location carrière", label : "Tarif pour une heure", price: "10€"},
        {id: 1, title: "Forfait mensuel location carrière", label : "Tarif pour le mois", price: "40€"},
        {id: 2, title: "Forfait annuel location carrière", label : "Tarif pour l'année", price: "350€"}

    ]},
    {id: 4, category: "Soins", details:
        [{id: 0, title: "Soins intensifs", label : "Plus de 2 interventions par jour, tarif journalier", price: "10€"},
        {id: 1, title: "Couvertures", label: "Gestion des couvertures pour les pensions pré, tarif mensuel", price: "30€"}
        ]},
    {id: 5 , category : "Tontes", details : [
        {id: 0, title: "Tonte manteau", label : "Tonte du corps moins le dos, les reins, la croupe", price: "35€"},
        {id: 1, title: "Tonte corps", label : "Tonte complète moins la tête et les membres", price: "50€"},
        {id: 2, title: "Tonte complète", label : "Tonte intégrale, tête et membres compris", price: "70€"}
        

    ]} 
]
export const team = "Équipe familiale et accueillante"
export const activities = [
    {id: 0, title: "Organisation de stages à l'année", details:"Equifeel, Éthologie, obstacle, dressage"}
]