
import Actualities from '../components/Actualities'
import { Hero } from '../components/Hero'
import Infos from '../components/Infos'
import OverlayPrez from '../components/OverlayPrez'
import {ReactComponent as Logo} from '../svg/logo.svg'
import EtHome from '../images/accueil/ET_home.png'
import ffe from '../images/common/medaillon_ffe.png'
import juju from '../images/common/jujuLac.jpg'
import balade from "../images/accueil/aCheval.png";
import coaching from "../images/accueil/coachingHome.jpg";
import { useEffect, useState } from 'react'
import sea from '../images/accueil/plage.png'
import montauriol01 from '../images/accueil/montauriol01.jpg'
import montauriol02 from '../images/accueil/montauriol02.jpg'
import montauriol03 from '../images/accueil/montauriol03.jpg'
import montauriol04 from '../images/accueil/montauriol04.jpg'
import chevauxEcurie from '../images/accueil/ecurieChevaux.jpg'
import MentionsFooter from '../components/MentionsFooter'
import Interceptor from '../components/Interceptor'
import AlaUne from '../components/aLaUne'

interface LandPhotoI{
  id: number,
  path: string,
  alt: string
}
const windowHeight = window.innerHeight;

export default function Home({...props}) { 
  const landPhotos = [
    {id: 0, path: montauriol01, alt: "Forêt du domaine"},
    {id: 1, path: montauriol02, alt: "Forêt du domaine"},
    {id: 2, path: montauriol03, alt: "Vignes du domaine"}
    
  ]
  const [animCards, setAnimCards] = useState<boolean>(false);

// didmount
useEffect(() => {
  window.scrollTo({top: 0,left: 0, behavior:'smooth'});
  window.addEventListener('scroll', checkIndexScroll, false);
  window.addEventListener('resize', checkIndexScroll, false);
  props.setActivNavElt(props.id);
  return()=>{
    props.setActivNavElt(-1);
  }
 }, []) 

 function handleCardsVisibility(cardDiv: HTMLElement) {
  const isVisibleDiv = formatVisibleDiv(cardDiv);    
  setAnimCards(isVisibleDiv ? true : false);
 
  
}
function formatVisibleDiv(theDiv: HTMLElement) {

  // la div
  const topTitle = theDiv.getBoundingClientRect().top;
  const bottomTitle = theDiv.getBoundingClientRect().bottom;
  const isVisible = bottomTitle > 0 && topTitle < windowHeight;

  return isVisible;
}
 const checkIndexScroll = () => {
    
  //OnscrollY: animation des cartes accueil
  const myCards = document.getElementById("anim-cards");  
  
  if (myCards){
      handleCardsVisibility(myCards);
  } 
  
  
}

  return (
    
    <div className={props.className}>  
      <div className="hero-comp">
        <Hero/>
        <div className='hero-group'>
          <Logo className='logo-hero'/>        
          <Infos {...props}/>       
        </div>
      </div>
      <Actualities/>
      
    <div className='title2'>
      Bienvenue à l'Écurie du Château | Villematier 
    </div>
   
    <AlaUne/>
    <div className='title-container'>
    <h2 className='title1'>Pension équestre</h2>
    <img src={EtHome} alt="ET" className='badge-intro'/>
    </div>    
    <div className='prez'>
    <p>Laura vous accueille dans le magnifique domaine de Montauriol, 
    en plein coeur de ses hectares de bois et de vignes. </p>
    <p>Venez découvrir l'authenticité d'une équipe familiale où la bonne humeur et l'assiduité sont au service des pensionnaires.</p>
    <div id="anim-cards" className='prez-photos'>
      {landPhotos.map((aPhoto: LandPhotoI)=>(
        <img key={aPhoto.id} className={animCards? 'anim-montauriol-photo' : 'montauriol-photo'} src={aPhoto.path} alt={aPhoto.alt}/>
      ))}
      
    </div>
    <h2 className='title1'>Pour votre compagnon</h2>
    <div className='prez'>
    Tout est organisé ici pour le confort de votre partenaire : 
    <p>Foin à volonté pour les pensionnaires pré, 2 rations de foin et de grain quotidiennes pour ceux de la formule box | paddock.</p>
    
    <p>Présente 24H sur 24, notre équipe s'entoure aussi des meilleurs praticiens vétérinaires, ostéopathes, et maréchaux. </p>
    </div>
    <div className="intro-wrapper">
    <div className='intro-block'>
      <img className='entree-img' src={montauriol04} alt="Entrée Écurie du château | Villematier"/>  
      <div className='text-photo-f'>
      <p>Les box sont curés et repaillés deux fois par semaine.</p>
      <img className='chevauxbox' src={chevauxEcurie} alt="Les chevaux au box"/>
    </div>
    </div>
    </div> 

    <h2 className='title1'>Pour vous</h2>
    <div className='prez-prime'>
      
    <div className='prez-first'>    
      <img className='logo-ffe' src={ffe} alt="logo-ffe"/>    
      <span>Pour le cavalier, nous faisons appel à plusieurs moniteurs diplômés FFE et / ou spécialistes renommés. Ainsi, nous proposons des cours collectifs, des stages ou des sorties tout au long de l'année.</span>
      
    </div>
    <div className='img-block-home'>
      <div className='copyright-container'>© Ingrid Equilys</div>   
      <img className='jujuIngrid' src={juju} alt="EquiLys Ingrid au lac"/>
    </div>
    
    </div> 
    <div className='prez-prime'>     
      <div className='img-block-home-mr2'>
        <div className='copyright-container'>@Ingrid Equilys</div>   
        <img className='seaimg' src={sea} alt="EquiLys Ingrid à la mer"/>
        
      </div>
      
      
     </div> 
     <div className='title2'>
      Les concours
     </div>
     
      <div className="img-txt">   
      <div className='overlay-text home'>
      À la demande, nous assurons également le coaching en concours.
      </div>
      <Interceptor className='interceptor'>
      <img className='coaching-img home' src={coaching} alt="Coaching" />
      </Interceptor>
      </div>    
    </div>
    <div className='title2'>
      Les balades
    </div>
    <div className="img-txt">
    <Interceptor className='interceptor-path'>
     <img className='overlay-img home' src={balade} alt="Les chemins de balade" />
    </Interceptor>
    <div className='overlay-text home'>Au départ des Écuries, vous avez accès à une variété de chemins de balade, à travers le magnifique domaine de Montauriol jusqu'à la forêt de Vacquiers.
      Laura accompagne les propriétaies avec plaisir, en groupe de tous niveaux.
    </div>
    </div>
    <OverlayPrez/>     
    
    <MentionsFooter className="footer-wrapper"/>
    </div>    
    
    )
  }
