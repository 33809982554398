import React, { useEffect, useRef, useState } from 'react';
import { PriorityBlock } from '../common/StyledComponents';
import "../style/HorizontalScrollSnap.css";
import ArrowAlone from './ArrowAlone';
import EquilysTraining from './EquilysTraining';
import { ScrollNav } from './ScrollNav';
import { trainingData } from '../data';
import {trainingFeb} from '../data';

interface ScrollNavI{
    id: number, 
    isActiv: boolean, 
    launchAnim: boolean
}

interface BlockData{
    id: number,   
    date: string, 
    description: string,  
    background: string | null | undefined,
    alt: string | null | undefined,
    actuClass?: string
}

interface AnimElt{
    id: number,
    isToAnimate: boolean
}
/* interface ArrowProps{
    color: string,
    height: number,
    width: number,
    isLeft: boolean,
    className: string
} */


export default function HorizontalScrollSnap(props: {blockData: BlockData[]}) {

    const timerAutoScroll = useRef<any>(null);
    const timerStopAutoScroll = useRef<any>(null);
    const timerNavElts = useRef<any>(null);
    const [launchAnimById, setLaunchAnimById] = useState<{id: number, launchAnim: boolean, isActiv: boolean}[]>([]);
    const [activPresta, setActivPresta] = useState<number>(0);
    const [priorityIds, setPriorityIds] = useState<number[]>([]);
    const activPrestaRef = useRef(activPresta);    
    activPrestaRef.current = activPresta;
    //swipe
    const[swipeStartPosition, setSwipeStartPosition] = useState<number|null>(null);
    const[mouseStartPosition, setMouseStartPosition] = useState<number|null>(null);
    
    /**compoMount */
    useEffect(()=>{
        // init nav elements with launchAnim on compo mount
        const elements = initElementsWithLaunchAnim();
        setLaunchAnimById(elements);

        const priorityTable = props.blockData.map((aData: BlockData)=>{
            return aData.id;
        });
        setPriorityIds(priorityTable);
    },[])
    
    useEffect(() => {
        // set ActivNavElt avec retard pour laisser l'anim se jouer
        
            // on passe l'élément à actif une seconde plus tard
            timerNavElts.current = setTimeout(() => {
                const elements = props.blockData && props.blockData.map((anActu:BlockData)=>{
                    let anElement: ScrollNavI;
                    if ( priorityIds[0] === anActu.id){
                        anElement = {id: anActu.id, launchAnim: launchAnimById[anActu.id].launchAnim, isActiv: true};
                        return anElement;
                    }else{
                        anElement =  {id: anActu.id, launchAnim: launchAnimById[anActu.id].launchAnim, isActiv: false};
                        return anElement;
                    }
                });
                setLaunchAnimById(elements);
                }, 300);
     
        return () => {           
          
            if (timerAutoScroll.current){
                clearTimeout(timerAutoScroll.current);
            }
            if(timerStopAutoScroll.current){
                clearTimeout(timerStopAutoScroll.current);
            }
            if(timerNavElts.current){
                clearTimeout(timerNavElts.current);
            } 
            
        }
    });


const handleTouchStart=(touchStartEvent: React.TouchEvent<HTMLDivElement>): void=> {
    // recup position touchStart
    //touchStartEvent.preventDefault();
    const posStart = touchStartEvent.touches[0].clientX;
    setSwipeStartPosition(posStart);
    console.log("position start : ", posStart);
    /* 
    throw new Error('Function not implemented.'); */
}

const handleTouchMove=(touchMoveEvent: React.TouchEvent<HTMLDivElement>)=>{
   
   /* let changes = touchMoveEvent.changedTouches;
   
   for (let i = 0; i<changes.length; i++){
       if(startPosition){
    if(changes[i].clientX > startPosition){       
        console.log("go left : ", changes[i]);
        clickNext();
    }else{
        console.log("go right : ", changes[i]);
        goPrevious();
    }

   } 
} */
}

const handleTouchEnd=(touchEndEvent: React.TouchEvent<HTMLDivElement>)=>{
    let changes = touchEndEvent.changedTouches;
   if(changes){
   for (let i = 0; i<changes.length; i++){
       if (swipeStartPosition){
    if(changes[i].clientX > swipeStartPosition){       
        goPrevious(); 
    }else if (changes[i].clientX < swipeStartPosition){        
        goNext();
    }
}

   } 
}
    console.log("handle mouse touch end");
}
function goPrevious(){
    const tempPrior = priorityIds.map((priorityId: number)=>{
        return priorityId !== props.blockData.length-1 ?  priorityId + 1 : 0;
    })
    setPriorityIds(tempPrior);
    console.log("click prev : " , tempPrior);
}
function goNext(){
    const tempPrior = priorityIds.map((priorityId: number)=>{
        return priorityId === 0 ?  props.blockData.length-1 : priorityId - 1;
    })
    setPriorityIds(tempPrior);
    console.log("click next : " , tempPrior);
}

const clickPrevious =(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
    e.preventDefault();
    goNext();
    
}
const clickNext =(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
   e.preventDefault();
   goPrevious();
  
}
function initElementsWithLaunchAnim(): ScrollNavI[]{
    let elements: ScrollNavI[];
   
    //initialisation en deux temps : d'abord on lance l'anim, on ne retourne pas void
    elements = props.blockData.map((aData: BlockData)=>{
        let anElement: ScrollNavI;
        const launchAnim = aData.id === priorityIds[0] ? true : false;        
        anElement = {id: aData.id, launchAnim: launchAnim, isActiv: false};
        return anElement;
    });   
    return elements;
}
    /**
     * 
     */
 
 const rightArrowProps = {color:"#212E53", className:'arrow-card', isLeft:false, width:'50px', height:'50px'};
    const leftArrowProps = {color:"#212E53", className:'arrow-card', isLeft:true, width:'50px', height:'50px'}
    
    return (
        <>
        
        <div className='go-block'>
        
        <div id="scroll-block" className='scrolling-block'
            onScroll={(e)=>{console.log("I'm scrolling")}}
            onTouchStart={touchStartEvent => handleTouchStart(touchStartEvent)}
            onTouchMove={touchMoveEvent => handleTouchMove(touchMoveEvent)}
            onTouchEnd={(e) => handleTouchEnd(e)}         
        
        >
       
        <div className='elts-block'>
                {props.blockData.map(data=>
                    <PriorityBlock dataId={data.id} numberOfItems={props.blockData.length-1} priorityId={priorityIds[data.id]} key={data.id} id={data.id+"-presta"}>
                        <div className={priorityIds[data.id] === 0 ? "block-img" : "no-block-img"}>
                            <div className='block-icon'>
                                <span>{data.date}</span>
                            </div>
                            <div className={priorityIds[data.id] === 0  ? 'block-main' : 'block-main-blur'}> 
                            
                                <div className='block-title'><h2>{data.description}</h2></div>
                                    {data.background && data.alt && <img className={data.actuClass} src={data.background} alt={data.alt}/>}
                                    {data.id === 6 && 
                                    <EquilysTraining 
                                     title="Stage Etho et embuches avec"
                                     titleClass="title-fiche-actu"
                                     trainingData={trainingData}
                                     />}
                                     {data.id === 1 && 
                                    <EquilysTraining 
                                     title="Stage Etho, embuches et longues rênes avec"
                                     titleClass="title-fiche-actu"
                                     trainingData={trainingFeb}
                                     />}
                                
                            </div> 
                        </div>
                       
                    </PriorityBlock> 
                                 
                )}
        </div>
        <div className='buttons-block'>
                <button className='scroll-buttons' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{clickPrevious(e);}}>
                    <ArrowAlone {...leftArrowProps}/>
                </button>    
                <button className='scroll-buttons' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{clickNext(e)}}>
                    <ArrowAlone {...rightArrowProps}/>
                </button>
        </div>
        <ScrollNav 
        elements={launchAnimById}/>
        </div>
        
        </div>
        
        </>
    )
}

