import React from 'react'

export default function Infos({...props}) {
    return (
        <div className='infos-container'>
        <div className='hour-container'>
        Horaires : de 8h00 à 20h00 du lundi au dimanche
        </div>
        <div>
        Localisation : Au carrefour de Montauban et Toulouse, à 45 minutes d'Albi 
        </div>
        <div>
        Tel :  {props.tel}
        </div>
        </div>
        )
    }
