import React from 'react'

import Equilys from '../images/common/equilys.png'
import {ReactComponent as Logo} from '../svg/logo.svg'


interface ITrainingData{
    id: number,
    title?: string,
    detail: string |string[],
    price: string |string[],
    image?: string | null | undefined,
    alt?: string | null | undefined
}


export default function EquilysTraining({...props}) {
  return (
<div className='img-block' >
<div className='etho-container'>
<span className='title1-actu'>{props.title} </span><span className={props.titleClass}>Ingrid Equilys</span>
<div className='article-etho'>
<div >
<Logo className='logo-etho'/>
</div>  
<div className='infos-etho'>
    {props.trainingData && props.trainingData.map((aData: ITrainingData)=>(
        <div key={aData.id}>            
            {aData.title && <div className='title6'>{aData.title}</div>}
                {Array.isArray(aData.detail) ? 
                    aData.detail.map((aDetail: string, index: number)=>(
                    aDetail && <span key={index} className='etho-p'>{aDetail}&nbsp;</span>
            )):
            aData.detail &&<p className='etho-p'>{aData.detail}</p>
        }
        &nbsp; {Array.isArray(aData.price)? aData.price.map((aPrice: string, index: number)=>(
                    <span key={index} className='etho-p'>{aPrice}</span>
            )):
            <p className='etho-p'>{aData.price}</p> 
        }
        </div>
    ))}                                  

</div>
<div className='logo-etho'>
<img className='img-etho' src={Equilys} alt="logo Equilys"/>
</div> 
</div>
</div>
</div>
  )
}


